export const CREATE_CARLIST = "CREATE_CARLIST";
export const CREATE_CARLIST_SUCCESS = "CREATE_CARLIST_SUCCESS";
export const CREATE_CARLIST_FAILURE = "CREATE_CARLIST_FAILURE";

export const GET_CARLIST = "GET_CARLIST";
export const GET_CARLIST_SUCCESS = "GET_CARLIST_SUCCESS";
export const GET_CARLIST_FAILURE = "GET_CARLIST_FAILURE";

export const UPDATE_CARLIST = "UPDATE_CARLIST";
export const UPDATE_CARLIST_SUCCESS = "UPDATE_CARLIST_SUCCESS";
export const UPDATE_CARLIST_FAILURE = "UPDATE_CARLIST_FAILURE";


export const DELETE_CARLIST = "DELETE_CARLIST";
export const DELETE_CARLIST_SUCCESS = "DELETE_CARLIST_SUCCESS";
export const DELETE_CARLIST_FAILURE = "DELETE_CARLIST_FAILURE";