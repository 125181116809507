import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  CREATE_DESIGNATIONS,
  GET_DESIGNATIONS,
  UPDATE_DESIGNATIONS,
  DELETE_DESIGNATIONS
} from "../actions/designations/actionTypes";
import {
  createdesignationsSuccess,
  createdesignationsFailure,
  getdesignationsSuccess,
  getdesignationsFailure,
  updatedesignationsSuccess,
  updatedesignationsFailure,
  deletedesignationsSuccess,
  deletedesignationsFailure
} from "../actions/designations/index";
import { pushNotification, } from "../utils/notifications";
import {
  postRequestWithTenat,
  getRequestWithTenant,
  patchRequestWithTokenTenant,
  deleteRequestWithTokenTenant
} from "./request";
import URls from "../constants/urls";

// //course category generator function
function* createdesignationsCall(action) {
  try {
    const response = yield call(postRequestWithTenat, URls.designations, action.payload);

    if (response?.status === 200) {
      pushNotification(
        `${response?.data.message}`,
        "success",
        "TOP_CENTER",
        1000
      );
      yield put(createdesignationsSuccess(response.data));
      action.payload.navigate("/designations")
    } else {
      pushNotification(`${response?.data?.message}`, "");
    }
  } catch (error) {
    yield put(createdesignationsFailure());
  }
}

function* updatedesignationsCall(action) {
  try {
    const response = yield call(patchRequestWithTokenTenant, `${URls.designations}/${action.payload.id}`,action.payload.data);
    if (response?.status === 200) {
      pushNotification(
        `${response?.data.message}`,
        "success",
        "TOP_CENTER",
        1000
      );
      yield put(updatedesignationsSuccess(response.data));
    } else {
      pushNotification(`${response?.data?.message}`, "");
    }
  } catch (error) {
    yield put(updatedesignationsFailure());
  }
}

function* deletedesignationsCall(action) {
  try {
    const response = yield call(deleteRequestWithTokenTenant, `${URls.designations}/${action.payload.id}`);
  
    if (response?.status === 200) {
      pushNotification(
        `${response?.data.message}`,
        "success",
        "TOP_CENTER",
        1000
      );
      yield put(deletedesignationsSuccess(response.data));
    } else {
      pushNotification(`${response?.data?.message}`, "");
    }
  } catch (error) {
    yield put(deletedesignationsFailure());
  }
}

function* getdesignationsCall(action) {
  try {
    console.log(action.payload,"=------------------------------")
    const response = yield call(getRequestWithTenant, `${URls.designations}?limit=${action.payload.filter.pageSize}&page=${action.payload.filter.pageNumber}`);

    if (response?.status === 200) {
      yield put(getdesignationsSuccess(response.data));
    }
  } catch (error) {
    yield put(getdesignationsFailure());
  }
}


function* watchGetRequest() {
  yield takeLatest(CREATE_DESIGNATIONS, createdesignationsCall);
  yield takeLatest(GET_DESIGNATIONS, getdesignationsCall);
  yield takeLatest(UPDATE_DESIGNATIONS, updatedesignationsCall);
  yield takeLatest(DELETE_DESIGNATIONS, deletedesignationsCall);
}
export default function* sagas() {
  yield all([watchGetRequest()]);
}
