import React, { useContext, useEffect } from "react";
import "../components/comaon.css";
import Topbar from "../components/landingPageComponent/topBar";
import NavBar from "../components/landingPageComponent/navBar";
import Footer from "../components/landingPageComponent/footer";
import { FeedbackContext } from "../context/FeedbackContext";
// import PortfolioModel from './modal'
function Technologies() {
  const { TechnologiesList } = useContext(FeedbackContext);

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <Topbar />
      <NavBar />
      <section id="technologies" className="sect">
        <div className="container">
          <div className="section-header">
            <h3>Technologies</h3>
          </div>
          <>
            <div className="container mt-5">
              <div className="row">
                {React.Children.toArray(
                  TechnologiesList.map((item, index) => {
                    return (
                      <>
                        {index >= 3 && (
                          <>
                            {index % 2 === 0 && (
                              <>
                                <div className="d-flex align-items-center">
                                  <div className="bo col-md-6">
                                    <h3 className="text-start">{item.title}</h3>
                                    <div className="desc_bg"> <p className="tech_text_desp">
                                      {item.description}
                                    </p></div>
                                   
                                  </div>
                                  <div className="mt-4 col-md-6">
                                    <img
                                      className="img-fluid"
                                      src={item.logo}
                                    ></img>
                                  </div>
                                </div>
                              </>
                            )}
                            {index % 2 !== 0 && (
                              <>
                                <div className="d-flex align-items-center">
                                  <div className="mt-4 col-md-6">
                                    <img
                                      className="img-fluid"
                                      src={item.logo}
                                    ></img>
                                  </div>
                                  <div className="bo col-md-6">
                                    <h3 className="text-start">{item.title}</h3>
                                    <div className="desc_bg">
                                    <p className="tech_text_desp">
                                      {item.description}
                                    </p>
                                    </div>
                                    <div className="mt-4"></div>
                                  </div>
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </>
                    );
                  })
                )}
              </div>
            </div>
          </>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Technologies;
