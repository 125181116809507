import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

function NavBar({ setLoader }) {
  const location = useLocation();
  const navigate = useNavigate();

  const isActive = (path) => {
    return location.pathname === path;
  };

  const handleScroll = (targetId) => {
    const target = document.getElementById(targetId);
    if (target) {
      window.scrollTo({
        top: target.offsetTop,
        behavior: 'smooth',
      });
    }
  };

  const navigateToHomeAndScroll = () => {
    if (location.pathname !== '/home') {
      console.log(location.pathname)
      navigate('/home');
      setTimeout(() => {
        handleScroll('contact-us');
      }, 500); // Adjust the delay as needed
    } else {
      handleScroll('contact-us');
    }
  };

  return (
    <>
      <Navbar bg="white" expand="lg">
        <Container>
          <HashLink to="/">
            <img src="/assets/logo.png" alt="logo" className="nav-logo img-fluid" />
          </HashLink>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll" className="justify-content-center">
            <Nav className="" navbarScroll>
              <Nav.Link as={Link} to="/home" className={isActive('/home') ? 'activeRoute' : ''}>
                Home
              </Nav.Link>
              <Nav.Link as={Link} to="/services" className={isActive('/services') ? 'activeRoute' : ''}>
                Services
              </Nav.Link>
              <NavDropdown title="Company" id="navbarScrollingDropdown">
                <NavDropdown.Item
                  as={Link}
                  to="/technologies"
                  className={isActive('/technologies') ? 'activeRoute' : ''}
                >
                  Technologies
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/careers"
                  className={isActive('/careers') ? 'activeRoute' : ''}
                >
                  Careers
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link
                as={Link}
                onClick={navigateToHomeAndScroll}
                to=""
                className={isActive('/contactUs') ? 'activeRoute' : ''}
              >
                Contact Us
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
          <div className="d-none d-xl-flex flex-shrink-0">
            <div id="phone-tada" className="d-flex align-items-center justify-content-center me-4">
              <a href="/" className="position-relative animated tada infinite">
                <i className="fa-solid fa-phone fa-2x" />
                <div className="position-absolute" style={{ top: '-7px', left: '20px' }}>
                  <span>
                    <i className="fa-solid fa-comment-dots" />
                  </span>
                </div>
              </a>
            </div>
            <div className="d-flex flex-column pe-4 border-end">
              <span className="text-brand-green">Have any questions?</span>
              <span className="text-secondary">Call: +92 334 1254887</span>
            </div>
            <div className="d-flex align-items-center justify-content-center ms-4">
              <a href="#">
                <i className="fa-solid fa-search fa-2x" />
              </a>
            </div>
          </div>
        </Container>
      </Navbar>
    </>
  );
}

export default NavBar;
