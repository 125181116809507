import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  CREATE_CAREER,
  GET_CAREER,
  UPDATE_CAREER,
  DELETE_CAREER
} from "../actions/career/actionTypes";
import {
  createcareerSuccess,
  createcareerFailure,
  getcareerSuccess,
  getcareerFailure,
  updatecareerSuccess,
  updatecareerFailure,
  deletecareerSuccess,
  deletecareerFailure
} from "../actions/career/index";
import { pushNotification, } from "../utils/notifications";
import {
  postRequestWithTenat,
  getRequestWithTenant,
  patchRequestWithTokenTenant,
  deleteRequestWithTokenTenant,
  postRequestWithTenatFormData
} from "./request";
import URls from "../constants/urls";

// //course category generator function
function* createcareerCall(action) {
  try {
    const response = yield call(postRequestWithTenatFormData, URls.career, action.payload);

    if (response?.status === 200) {
      pushNotification(
        `${response?.data.message}`,
        "success",
        "TOP_CENTER",
        1000
      );
      yield put(createcareerSuccess(response.data));
      action.payload.navigate("/career")
    } else {
      pushNotification(`${response?.data?.message}`, "");
    }
  } catch (error) {
    yield put(createcareerFailure());
  }
}

function* updatecareerCall(action) {
  try {
    const response = yield call(patchRequestWithTokenTenant, `${URls.career}/${action.payload.id}`,action.payload.data);
    if (response?.status === 200) {
      pushNotification(
        `${response?.data.message}`,
        "success",
        "TOP_CENTER",
        1000
      );
      yield put(updatecareerSuccess(response.data));
    } else {
      pushNotification(`${response?.data?.message}`, "");
    }
  } catch (error) {
    yield put(updatecareerFailure());
  }
}

function* deletecareerCall(action) {
  try {
    const response = yield call(deleteRequestWithTokenTenant, `${URls.career}/${action.payload.id}`);
  
    if (response?.status === 200) {
      pushNotification(
        `${response?.data.message}`,
        "success",
        "TOP_CENTER",
        1000
      );
      yield put(deletecareerSuccess(response.data));
    } else {
      pushNotification(`${response?.data?.message}`, "");
    }
  } catch (error) {
    yield put(deletecareerFailure());
  }
}

function* getcareerCall(action) {
  try {
    const response = yield call(getRequestWithTenant, `${URls.career}?limit=${action.payload.pageSize}&page=${action.payload.pageNumber}`);

    if (response?.status === 200) {
      yield put(getcareerSuccess(response.data));
    }
  } catch (error) {
    yield put(getcareerFailure());
  }
}


function* watchGetRequest() {
  yield takeLatest(CREATE_CAREER, createcareerCall);
  yield takeLatest(GET_CAREER, getcareerCall);
  yield takeLatest(UPDATE_CAREER, updatecareerCall);
  yield takeLatest(DELETE_CAREER, deletecareerCall);
}
export default function* sagas() {
  yield all([watchGetRequest()]);
}
