import React, { useContext } from "react";
import './comaon.css';
import { FeedbackContext } from "../context/FeedbackContext";

function Clients() {
  function randomWidth() {
    return Math.floor(Math.random() * (200 - 100 + 1)) + 130;
  }
  const { ClientNew } = useContext(FeedbackContext);

  return (
    <>
      <section id="clients" className='clients_wrap'>
      <h1 className="my-3">Our Clients</h1>
        <div className="">
          <div className="container-fluid ">
            <header className="section-header">
              <h3 className="mt-3"></h3>
            </header>
            <div className="aboutImgs d-flex flex-wrap">
              {ClientNew.map((item, index) => (
                <div
                  key={index}
                  style={item.url ? { cursor: 'pointer' } : {}}
                  className="py-3"
                  onClick={() => {
                    if (item.url) {
                      window.open(`${item.url}`);
                    }
                  }}
                >
                  <img
                  style={{width:`${randomWidth()}px`}}
                    className="imgk"
                    src={item.img}
                    alt=""
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Clients;
