export const CREATE_CONTACTUS = "CREATE_CONTACTUS";
export const CREATE_CONTACTUS_SUCCESS = "CREATE_CONTACTUS_SUCCESS";
export const CREATE_CONTACTUS_FAILURE = "CREATE_CONTACTUS_FAILURE";

export const GET_CONTACTUS = "GET_CONTACTUS";
export const GET_CONTACTUS_SUCCESS = "GET_CONTACTUS_SUCCESS";
export const GET_CONTACTUS_FAILURE = "GET_CONTACTUS_FAILURE";

export const UPDATE_CONTACTUS = "UPDATE_CONTACTUS";
export const UPDATE_CONTACTUS_SUCCESS = "UPDATE_CONTACTUS_SUCCESS";
export const UPDATE_CONTACTUS_FAILURE = "UPDATE_CONTACTUS_FAILURE";


export const DELETE_CONTACTUS = "DELETE_CONTACTUS";
export const DELETE_CONTACTUS_SUCCESS = "DELETE_CONTACTUS_SUCCESS";
export const DELETE_CONTACTUS_FAILURE = "DELETE_CONTACTUS_FAILURE";