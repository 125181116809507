import React, { useState, useEffect } from "react";
import '../comaon.css';
import Topbar from "./topBar";
import NavBar from "./navBar";
import MainCarousel from "./Carousel";
import Testimonial from "./Testimonial";
import Blog from "./blog";
import Footer from "./footer";
import OurTeam from "./OurTeam";
import ContactUs from "./contactUs";
import ChooseUS from "./ChooseUs";
import Clients from "../clients";
import Service from "./services";
import Technologies from "./technologies";

export default function LandingPage() {
  return (
        <>
          <Topbar />
          <NavBar />
          <MainCarousel />
          <Technologies/>
          <Testimonial />
          {/* <OurTeam /> */}
          <ChooseUS />
          <Service/>
          {/* <Blog/> */}
          <Clients />
          <ContactUs />
          <Footer />
        </>
  );
}
