export const CREATE_DESIGNATIONS = "CREATE_DESIGNATIONS";
export const CREATE_DESIGNATIONS_SUCCESS = "CREATE_DESIGNATIONS_SUCCESS";
export const CREATE_DESIGNATIONS_FAILURE = "CREATE_DESIGNATIONS_FAILURE";

export const GET_DESIGNATIONS = "GET_DESIGNATIONS";
export const GET_DESIGNATIONS_SUCCESS = "GET_DESIGNATIONS_SUCCESS";
export const GET_DESIGNATIONS_FAILURE = "GET_DESIGNATIONS_FAILURE";

export const UPDATE_DESIGNATIONS = "UPDATE_DESIGNATIONS";
export const UPDATE_DESIGNATIONS_SUCCESS = "UPDATE_DESIGNATIONS_SUCCESS";
export const UPDATE_DESIGNATIONS_FAILURE = "UPDATE_DESIGNATIONS_FAILURE";


export const DELETE_DESIGNATIONS = "DELETE_DESIGNATIONS";
export const DELETE_DESIGNATIONS_SUCCESS = "DELETE_DESIGNATIONS_SUCCESS";
export const DELETE_DESIGNATIONS_FAILURE = "DELETE_DESIGNATIONS_FAILURE";