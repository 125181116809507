import {
  CREATE_CAREER,
  CREATE_CAREER_SUCCESS,
  CREATE_CAREER_FAILURE,
  GET_CAREER,
  GET_CAREER_SUCCESS,
  GET_CAREER_FAILURE,
  UPDATE_CAREER,
  UPDATE_CAREER_SUCCESS,
  UPDATE_CAREER_FAILURE,
  DELETE_CAREER,
  DELETE_CAREER_SUCCESS,
  DELETE_CAREER_FAILURE
} from "../actions/career/actionTypes";

const initialState = {
  getListLoading: false,
  data: null,
  createData:null
};

// course category reducer funtions
const createcareer = (state, action) => ({
  ...state,
  getListLoading: true,
});

const createcareerSuccess = (state, action) => {

  return {
    ...state,
    getListLoading: false,
    createData: action.payload,
  };
};

const createcareerFailed = (state, action) => ({
  ...state,
  getListLoading: false,
  createData: [],
});

const getcareer = (state, action) => ({
  ...state,
  // getListLoading: true,
});

const getcareerSuccess = (state, action) => {
 
  return {
    ...state,
    // getListLoading: false,
    data: action.payload,
  };
};

const getcareerFailed = (state, action) => ({
  ...state,
  // getListLoading: false,
  data: [],
});

const updatecareer = (state, action) => ({
  ...state,
   getListLoading: true,
});

const updatecareerSuccess = (state, action) => {

  return {
    ...state,
    getListLoading: false,
    data: action.payload,
  };
};  
const updatecareerFailed = (state, action) => ({
  ...state,
  getListLoading: false,
  data: [],
});

const deletecareer = (state, action) => ({
  ...state,
   getListLoading: true,
});

const deletecareerSuccess = (state, action) => {
  
  return {
    ...state,
    getListLoading: false,
  };
};  
const deletecareerFailed = (state, action) => ({
  ...state,
  getListLoading: false,
  data: [],
});

const careerReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_CAREER:
      return createcareer(state, action);
    case CREATE_CAREER_SUCCESS:
      return createcareerSuccess(state, action);
    case CREATE_CAREER_FAILURE:
      return createcareerFailed(state, action);
    case GET_CAREER:
      return getcareer(state, action);
    case GET_CAREER_SUCCESS:
      return getcareerSuccess(state, action);
    case GET_CAREER_FAILURE:
      return getcareerFailed(state, action);
    case UPDATE_CAREER:
      return updatecareer(state, action);
    case UPDATE_CAREER_SUCCESS:
      return updatecareerSuccess(state, action);
    case UPDATE_CAREER_FAILURE:
      return updatecareerFailed(state, action);
    case DELETE_CAREER:
      return deletecareer(state, action);
    case DELETE_CAREER_SUCCESS:
      return deletecareerSuccess(state, action);
    case DELETE_CAREER_FAILURE:
      return deletecareerFailed(state, action);
    default:
      return state;
  }
};

export default careerReducer;
