import { all } from "redux-saga/effects";
import authSagas from "./authSagas";
import department from "./departmentSaga";
import resetPasswordSaga from "./resetPasswordSaga"
import carList from "./carListSaga";
import attendanceSaga from "./attendanceSaga"
import emailTemplateSaga from "./emailTemplateSaga"
import addRollSaga from "./addRolsSaga"
import UserList from "./UserListSaga";
import designations from "./designationsSaga";
import contactUs from "./contactUsSaga";
import career from "./careerSaga";

export default function* rootSaga() {
  yield all([UserList(),contactUs(),designations(), authSagas(), resetPasswordSaga(),career(), carList(), attendanceSaga(), emailTemplateSaga(),addRollSaga(),department()]);  
}
