import {
  CREATE_DESIGNATIONS,
  CREATE_DESIGNATIONS_SUCCESS,
  CREATE_DESIGNATIONS_FAILURE,
  GET_DESIGNATIONS,
  GET_DESIGNATIONS_SUCCESS,
  GET_DESIGNATIONS_FAILURE,
  UPDATE_DESIGNATIONS,
  UPDATE_DESIGNATIONS_SUCCESS,
  UPDATE_DESIGNATIONS_FAILURE,
  DELETE_DESIGNATIONS,
  DELETE_DESIGNATIONS_SUCCESS,
  DELETE_DESIGNATIONS_FAILURE
} from "../actions/designations/actionTypes";

const initialState = {
  getListLoading: false,
  data: null,
  createData:null
};

// course category reducer funtions
const createdesignations = (state, action) => ({
  ...state,
  getListLoading: true,
});

const createdesignationsSuccess = (state, action) => {

  return {
    ...state,
    getListLoading: false,
    createData: action.payload,
  };
};

const createdesignationsFailed = (state, action) => ({
  ...state,
  getListLoading: false,
  createData: [],
});

const getdesignations = (state, action) => ({
  ...state,
  // getListLoading: true,
});

const getdesignationsSuccess = (state, action) => {
 
  return {
    ...state,
    // getListLoading: false,
    data: action.payload,
  };
};

const getdesignationsFailed = (state, action) => ({
  ...state,
  // getListLoading: false,
  data: [],
});

const updatedesignations = (state, action) => ({
  ...state,
   getListLoading: true,
});

const updatedesignationsSuccess = (state, action) => {

  return {
    ...state,
    getListLoading: false,
    data: action.payload,
  };
};  
const updatedesignationsFailed = (state, action) => ({
  ...state,
  getListLoading: false,
  data: [],
});

const deletedesignations = (state, action) => ({
  ...state,
   getListLoading: true,
});

const deletedesignationsSuccess = (state, action) => {
  
  return {
    ...state,
    getListLoading: false,
  };
};  
const deletedesignationsFailed = (state, action) => ({
  ...state,
  getListLoading: false,
  data: [],
});

const designationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_DESIGNATIONS:
      return createdesignations(state, action);
    case CREATE_DESIGNATIONS_SUCCESS:
      return createdesignationsSuccess(state, action);
    case CREATE_DESIGNATIONS_FAILURE:
      return createdesignationsFailed(state, action);
    case GET_DESIGNATIONS:
      return getdesignations(state, action);
    case GET_DESIGNATIONS_SUCCESS:
      return getdesignationsSuccess(state, action);
    case GET_DESIGNATIONS_FAILURE:
      return getdesignationsFailed(state, action);
    case UPDATE_DESIGNATIONS:
      return updatedesignations(state, action);
    case UPDATE_DESIGNATIONS_SUCCESS:
      return updatedesignationsSuccess(state, action);
    case UPDATE_DESIGNATIONS_FAILURE:
      return updatedesignationsFailed(state, action);
    case DELETE_DESIGNATIONS:
      return deletedesignations(state, action);
    case DELETE_DESIGNATIONS_SUCCESS:
      return deletedesignationsSuccess(state, action);
    case DELETE_DESIGNATIONS_FAILURE:
      return deletedesignationsFailed(state, action);
    default:
      return state;
  }
};

export default designationsReducer;
