import Carousel from "react-bootstrap/Carousel";

function MainCarousel() {
const word = "SALDIRI";
const colors = ["#E84336", "#103F72", "#34A754", "#FBBC16", "#517BBD"];
  return (
    <div className="main-header-cur">
    <Carousel >
    <Carousel.Item>
        <img src="/assets/carousel-2.jpg"  className="img-fluid" alt="Third slide" />
          <Carousel.Caption>
        <div className="container carousel-content">
              <h6 className="text-brand-green h4 animated fadeInUp">
            {word.split("").map((char, index) => (
              <span key={index} style={{ color: colors[index] }}>
                {char}
              </span>
            ))}
          </h6>
          <h1 className="text-white fw-bold display-1 mb-4 animated fadeInRight">
          Quality Digital Services You Really Need!
          </h1>
          <a href="/contactUs" className="me-2">
            <button
              type="button"
              className="px-md-5 px-2 py-sm-3 px-sm-5 btn btn-secondary rounded-pill carousel-content-btn1 animated fadeInLeft"
              >
              Join Us
            </button>
          </a>
          <a href="/contactUs" className="ms-2">
            <button
              type="button"
              className="px-md-5 px-2 py-sm-3 px-sm-5 btn btn-primary rounded-pill carousel-content-btn2 animated fadeInRight"
              >
              Contact Us
            </button>
          </a>
              </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img src="assets/carousel-1.jpg" className="img-fluid" alt="First slide" />
        <Carousel.Caption>
        <div className="container carousel-content">
          <h6 className="text-brand-green h4 animated fadeInUp">Best IT Solutions</h6>
          <h1 className="text-white fw-bold display-1 mb-4 animated fadeInRight">
          Business | Technology | Empowerment
          </h1>
          <p className="mb-4 text-white fs-5 animated fadeInDown">
          Highly skilled individuals equiped with the latest technologies to empower your business and needs.
          </p>
          <a href="/contactUs" className="me-2">
            <button
              type="button"
              className="px-md-5 px-2 py-sm-3 px-sm-5 btn btn-secondary rounded-pill carousel-content-btn1 animated fadeInLeft"
              >
              Join Us
            </button>
          </a>
          <a href="/contactUs" className="ms-2">
            <button
              type="button"
              className="px-md-5 px-2 py-sm-3 px-sm-5 btn btn-primary rounded-pill carousel-content-btn2 animated fadeInRight"
              >
              Contact Us
            </button>
          </a>
              </div>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
    </div>
  );
}

export default MainCarousel;
