import React from "react";
const serviceTitles =  [
    "Amazon Web Services",
    "Mobile App Development",
    "Web Development and Design",
    "Data Analytics",
    "Python Programming",
    "UI/UX & Digital Marketing"
  ]
export default function Footer() {
  return (
    <>
      <div className="container-fluid bg-dark text-white-50 footer pt-5">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.1s">
              <a href="index.html" className="d-inline-block mb-3">
                <h1 className="text-white">
                Saldiri<span className="text-brand-primary">SMC</span>
                </h1>
              </a>
              <p className="mb-0">
                At Saldiri, we are committed to providing innovative solutions that meet the needs of our clients.
              </p>
            </div>
            <div className="col-md-6 col-lg-3 wow fadeIn footer-animation text-left" data-wow-delay="0.3s">
              <h5 className="text-white mb-4">Get In Touch</h5>
              <p>
                <i className="fa fa-map-marker-alt me-3"></i>Daftarkhuwan DHA phase 5 , Lahore
              </p>
              <p>
                <i className="fa fa-phone-alt me-3"></i>+92 334 1254887
              </p>
              <p>
                <i className="fa fa-envelope me-3"></i> <a href="mailto:queries@saldri.com" style={{color:'rgba(255,255,255,.5)'}}>queries@saldri.com</a>
              </p>
              <div className="d-flex pt-2 d-none">
                <a className="btn btn-outline-light btn-social" href="">
                  <i className="fab fa-twitter"></i>
                </a>
                <a className="btn btn-outline-light btn-social" href="">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a className="btn btn-outline-light btn-social" href="">
                  <i className="fab fa-youtube"></i>
                </a>
                <a className="btn btn-outline-light btn-social" href="">
                  <i className="fab fa-instagram"></i>
                </a>
                <a className="btn btn-outline-light btn-social" href="">
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 wow fadeIn footer-animation text-left" data-wow-delay="0.5s">
              <h5 className="text-white mb-4">Popular Link</h5>
              <a className="btn btn-link" href="">
                About Us
              </a>
              <a className="btn btn-link" href="contactUs">
                Contact Us
              </a>
              <a className="btn btn-link" href="">
                Privacy Policy
              </a>
              <a className="btn btn-link" href="">
                Terms & Condition
              </a>
              <a className="btn btn-link" href="careers">
                Careers
              </a>
            </div>
            <div className="col-md-6 col-lg-3 wow fadeIn footer-animation text-left" data-wow-delay="0.7s">
              <h5 className="text-white mb-4">Our Services</h5>
              {serviceTitles.map((title, index) => (
                <a key={index} className="btn btn-link" href="services">
                  {title}
                </a>
              ))}
            </div>
          </div>
        </div>
        <div className="container wow fadeIn footer-animation" data-wow-delay="0.1s">
          <div className="copyright">
            <div className="row">
              <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                &copy;{" "}
                <a className="border-bottom" href="#">
                  Saldiri SMC Pvt limited
                </a>
                , All Right Reserved.
              </div>
              <div className="col-md-6 text-center text-md-end">
                <div className="footer-menu d-none">
                  <a href="/">Home</a>
                  <a href="">Cookies</a>
                  <a href="contactUs">Help</a>
                  <a href="">FAQs</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
