import React from 'react';
import { ServicesAll } from '../../data';
export default function Service() {
  return (
    <>
      <div className="container-fluid services py-5 mb-5" style={{boxShadow: '0 4px 2px -2px gray'}}>
        <div className="container">
          <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{ maxWidth: "600px" }}>
            <h5 className="text-brand-primary">Our Services</h5>
            <h1>Services Built Specifically For Your Business</h1>
          </div>
          <div className="row g-5 services-inner">
            {ServicesAll.slice(0,3).map((service, index) => (
              <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay={`${0.3 + index * 0.2}s`} key={index}>
                <div className="services-item bg-light">
                  <div className="p-4 text-center services-content">
                    <div className="services-content-icon">
                      <i className={service.icon + " fa-7x mb-4 text-brand-primary"}></i>
                      <h4 className="mb-3">{service.title}</h4>
                      <p className="mb-4 description">{service.description}</p>
                      <a href="services" className="btn btn-secondary text-white px-5 py-3 rounded-pill">Read More</a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div>
                <a href="services" className="btn btn-primary bg-brand-primary text-white px-5 py-3 rounded-pill fit-content">See More</a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
