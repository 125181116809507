export const CREATE_CAREER = "CREATE_CAREER";
export const CREATE_CAREER_SUCCESS = "CREATE_CAREER_SUCCESS";
export const CREATE_CAREER_FAILURE = "CREATE_CAREER_FAILURE";

export const GET_CAREER = "GET_CAREER";
export const GET_CAREER_SUCCESS = "GET_CAREER_SUCCESS";
export const GET_CAREER_FAILURE = "GET_CAREER_FAILURE";

export const UPDATE_CAREER = "UPDATE_CAREER";
export const UPDATE_CAREER_SUCCESS = "UPDATE_CAREER_SUCCESS";
export const UPDATE_CAREER_FAILURE = "UPDATE_CAREER_FAILURE";


export const DELETE_CAREER = "DELETE_CAREER";
export const DELETE_CAREER_SUCCESS = "DELETE_CAREER_SUCCESS";
export const DELETE_CAREER_FAILURE = "DELETE_CAREER_FAILURE";