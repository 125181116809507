import React from "react";
import Carousel from 'react-bootstrap/Carousel';

const Client = [
  {
    id: 1,
    clientName: 'Afaq S.',
    profession: 'Program/Project Management | Service Delivery | Te',
    quote: 'Saldiri team is a capable engineering team with exceptional skills in multiple technology stacks. Commands in new technologies and quickly becomes productive by designing and implementing solutions. Well satisfied with the services provided on time.',
    imageSrc: '/assets/download1.svg'
  },
  {
    id: 2,
    clientName: 'Sadaf k.',
    profession: 'Business Support Manager',
    quote: 'Very reliable and efficient services. CEO is highly customer-focused with professional attitude. Highly recommended!',
    imageSrc: '/assets/download1.svg'
  },
  {
    id: 3,
    clientName: 'Arsalan N.',
    profession: 'Corporate Finance Specialist | Deals Modelling | Social Media and travel',
    quote: 'It is great working with Saldiri SMC PVT LTD. Looking forward to working on more projects. I must say Saldiri team is truly professional and delivers tasks right on time.',
    imageSrc: '/assets/download1.svg'
  }
];

export default function Testimonial() {
  return (
    <div style={{boxShadow: '0 4px 2px -2px gray'}}>

    <div className="container-xxl py-5">
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-lg-5 text-left wow fadeIn" data-wow-delay="0.1s">
            <div className="btn btn-sm border rounded-pill text-brand-primary px-3 mb-3">Testimonial</div>
            <h1 className="mb-4">What Say Our Clients!</h1>
            <p className="mb-4">At Saldiri, we value the feedback of our clients. Here's what some of them have to say about their experience working with us:</p>
            <p className="btn btn-primary bg-brand-primary rounded-pill px-4" href="">Read More</p>
          </div>
          <div className="col-lg-7 wow fadeIn" data-wow-delay="0.5s">
            <Carousel className="testimonial-carousel" interval={null} prevIcon={<i className="fa fa-chevron-left"></i>} nextIcon={<i className="fa fa-chevron-right"></i>}>
              {Client.map((testimonial, index) => (
                <Carousel.Item key={index}>
                  <div className="testimonial-item ps-5 position-relative">
                    <i className="fa fa-quote-left fa-2x text-brand-primary mb-3 position-absolute start-0 top-0"></i>
                    <p className="fs-4">{testimonial.quote}</p>
                    <div className="d-flex align-items-center">
                      <img className="img-fluid flex-shrink-0 rounded-circle" src={testimonial.imageSrc} alt={testimonial.clientName} style={{ width: '60px', height: '60px' }} />
                      <div className="ps-3">
                        <h5 className="mb-1">{testimonial.clientName}</h5>
                        <span>{testimonial.profession}</span>
                      </div>
                    </div>
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}
