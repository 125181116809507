import React,{useState,useCallback} from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { createcontactUs } from "../../actions/contactUs";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

export default function ContactUs() {
  const [token, setToken] = useState("");
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    message: Yup.string().required("Message is required"),
  });

  const handleSubmit = useCallback(async (values, { resetForm, setSubmitting }) => {
    try {
      if (token) await dispatch(createcontactUs(values));
      else return;
      resetForm();
    } catch (error) {
      setRefreshReCaptcha(!refreshReCaptcha);
      console.error("Error sending email:", error);
      alert("An error occurred while sending the email. Please try again later.");
    } finally {
      setSubmitting(false);
    }
  }, [dispatch, token, refreshReCaptcha]);

  const setTokenFunc = useCallback((getToken) => {
    setToken(getToken);
  }, []);
  return (
    <>
      <div id="contact-us" className="container-fluid py-md-5 py-3 mb-5">
        <div className="container">
          <div
            className="text-center mx-auto pb-5 wow fadeIn"
            data-wow-delay=".3s"
            style={{ maxWidth: "600px" }}
          >
            <h5 className="text-primary">Get In Touch</h5>
            <h1 className="mb-3">Contact for any query</h1>
          </div>
          <div className="contact-detail position-relative p-md-5 p-3">
            <div className="row g-5 mb-5 justify-content-center">
              <div
                className="col-xl-4 col-lg-6 wow fadeIn"
                data-wow-delay=".3s"
              >
                <div className="d-flex bg-light p-3 rounded">
                  <div
                    className="flex-shrink-0 btn-square d-flex justify-content-center align-items-center rounded-circle"
                    style={{
                      width: "64px",
                      height: "64px",
                      background: "#1842b6",
                    }}
                  >
                    <i
                      className="fas fa-map-marker-alt text-white"
                      style={{ fontSize: "24px" }}
                    ></i>
                  </div>
                  <div className="ms-3 text-left">
                    <h4 className="text-brand-primary">Address</h4>
                    <a
                      href="https://goo.gl/maps/Zd4BCynmTb98ivUJ6"
                      target="_blank"
                      className="h5"
                    >
                      Daftarkhwan 3nd Floor, D.H.A, Lahore
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-6 wow fadeIn"
                data-wow-delay=".5s"
              >
                <div className="d-flex bg-light p-3 rounded">
                  <div
                    className="flex-shrink-0 btn-square  d-flex justify-content-center align-items-center rounded-circle"
                    style={{
                      width: "64px",
                      height: "64px",
                      background: "#1842b6",
                    }}
                  >
                    <i
                      className="fa fa-phone text-white"
                      style={{ fontSize: "24px" }}
                    ></i>
                  </div>
                  <div className="ms-3 mb-3 mt-1 text-left">
                    <h4 className="text-brand-primary">Call Us</h4>
                    <a className="h5" href="tel:+0123456789" target="_blank">
                      +92 334 1254887
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-6 wow fadeIn"
                data-wow-delay=".7s"
              >
                <div className="d-flex bg-light p-3 rounded">
                  <div
                    className="flex-shrink-0 btn-square d-flex justify-content-center align-items-center rounded-circle"
                    style={{
                      width: "64px",
                      height: "64px",
                      background: "#1842b6",
                    }}
                  >
                    <i
                      className="fa fa-envelope text-white"
                      style={{ fontSize: "24px" }}
                    ></i>
                  </div>
                  <div className="ms-3 mb-3 mt-1 text-left">
                    <h4 className="text-brand-primary">Email Us</h4>
                    <a
                      className="h5"
                      href="mailto:queries@saldri.com"
                      target="_blank"
                    >
                      queries@saldri.com
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-5">
              <div className="col-lg-6 wow fadeIn" data-wow-delay=".3s">
                <div className="p-md-5 p-3 h-100 rounded contact-map">
                  <iframe
                    className="rounded w-100 h-100"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3402.972946984071!2d74.40295497550913!3d31.469930349632424!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3919067e846bc835%3A0x5cab931d9fedf223!2sDaftarkhwan%20%7C%20One%20(DHA)!5e0!3m2!1sen!2s!4v1707982625324!5m2!1sen!2s"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
              <div className="col-lg-6 wow fadeIn" data-wow-delay=".5s">
                <div className="p-md-5 p-3 rounded contact-form">
                  <Formik
                    initialValues={{
                      name: "",
                      email: "",
                      project: "",
                      message: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <div className="mb-4">
                          <Field
                            type="text"
                            name="name"
                            className="form-control border-0 py-3"
                            placeholder="Your Name"
                          />
                          <ErrorMessage
                            name="name"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div className="mb-4">
                          <Field
                            type="email"
                            name="email"
                            className="form-control border-0 py-3"
                            placeholder="Your Email"
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div className="mb-4">
                          <Field
                            type="text"
                            name="project"
                            className="form-control border-0 py-3"
                            placeholder="Project"
                          />
                          <ErrorMessage
                            name="project"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div className="mb-4">
                          <Field
                            as="textarea"
                            name="message"
                            className="w-100 form-control border-0 py-3"
                            rows="6"
                            cols="10"
                            placeholder="Message"
                          />
                          <ErrorMessage
                            name="message"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div className="text-start">
                          <button
                            type="submit"
                            className="btn btn-secondary text-white py-3 px-5"
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? "Sending..." : "Send Message"}
                          </button>
                        </div>
                        <GoogleReCaptcha
                          className="google-recaptcha-custom-class"
                          onVerify={setTokenFunc}
                          refreshReCaptcha={refreshReCaptcha}
                        />
      
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
