import {
  CREATE_CONTACTUS,
  CREATE_CONTACTUS_SUCCESS,
  CREATE_CONTACTUS_FAILURE,
  GET_CONTACTUS,
  GET_CONTACTUS_SUCCESS,
  GET_CONTACTUS_FAILURE,
  UPDATE_CONTACTUS,
  UPDATE_CONTACTUS_SUCCESS,
  UPDATE_CONTACTUS_FAILURE,
  DELETE_CONTACTUS,
  DELETE_CONTACTUS_SUCCESS,
  DELETE_CONTACTUS_FAILURE
} from "../actions/contactUs/actionTypes";

const initialState = {
  getListLoading: false,
  data: null,
  createData:null
};

// course category reducer funtions
const createcontactUs = (state, action) => ({
  ...state,
  getListLoading: true,
});

const createcontactUsSuccess = (state, action) => {

  return {
    ...state,
    getListLoading: false,
    createData: action.payload,
  };
};

const createcontactUsFailed = (state, action) => ({
  ...state,
  getListLoading: false,
  createData: [],
});

const getcontactUs = (state, action) => ({
  ...state,
  // getListLoading: true,
});

const getcontactUsSuccess = (state, action) => {
 
  return {
    ...state,
    // getListLoading: false,
    data: action.payload,
  };
};

const getcontactUsFailed = (state, action) => ({
  ...state,
  // getListLoading: false,
  data: [],
});

const updatecontactUs = (state, action) => ({
  ...state,
   getListLoading: true,
});

const updatecontactUsSuccess = (state, action) => {

  return {
    ...state,
    getListLoading: false,
    data: action.payload,
  };
};  
const updatecontactUsFailed = (state, action) => ({
  ...state,
  getListLoading: false,
  data: [],
});

const deletecontactUs = (state, action) => ({
  ...state,
   getListLoading: true,
});

const deletecontactUsSuccess = (state, action) => {
  
  return {
    ...state,
    getListLoading: false,
  };
};  
const deletecontactUsFailed = (state, action) => ({
  ...state,
  getListLoading: false,
  data: [],
});

const contactUsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_CONTACTUS:
      return createcontactUs(state, action);
    case CREATE_CONTACTUS_SUCCESS:
      return createcontactUsSuccess(state, action);
    case CREATE_CONTACTUS_FAILURE:
      return createcontactUsFailed(state, action);
    case GET_CONTACTUS:
      return getcontactUs(state, action);
    case GET_CONTACTUS_SUCCESS:
      return getcontactUsSuccess(state, action);
    case GET_CONTACTUS_FAILURE:
      return getcontactUsFailed(state, action);
    case UPDATE_CONTACTUS:
      return updatecontactUs(state, action);
    case UPDATE_CONTACTUS_SUCCESS:
      return updatecontactUsSuccess(state, action);
    case UPDATE_CONTACTUS_FAILURE:
      return updatecontactUsFailed(state, action);
    case DELETE_CONTACTUS:
      return deletecontactUs(state, action);
    case DELETE_CONTACTUS_SUCCESS:
      return deletecontactUsSuccess(state, action);
    case DELETE_CONTACTUS_FAILURE:
      return deletecontactUsFailed(state, action);
    default:
      return state;
  }
};

export default contactUsReducer;
