import {
    CREATE_CAREER,
    CREATE_CAREER_SUCCESS,
    CREATE_CAREER_FAILURE,
    GET_CAREER,
    GET_CAREER_SUCCESS,
    GET_CAREER_FAILURE,
    UPDATE_CAREER,
    UPDATE_CAREER_SUCCESS,
    UPDATE_CAREER_FAILURE,
    DELETE_CAREER,
    DELETE_CAREER_SUCCESS,
    DELETE_CAREER_FAILURE
  } from "./actionTypes";
  
  // Signin actions function
  export const createcareer = (formData) => ({
    type: CREATE_CAREER,
    payload: formData,
  });
  
  export const createcareerSuccess = (data) => ({
    type: CREATE_CAREER_SUCCESS,
    payload: data,
  });
  
  export const createcareerFailure = () => ({
    type: CREATE_CAREER_FAILURE,
  });

  export const getcareer = (data) => ({
    type: GET_CAREER,
    payload: data,
  });
  
  export const getcareerSuccess = (data) => ({
    type: GET_CAREER_SUCCESS,
    payload: data,
  });
  
  export const getcareerFailure = () => ({
    type: GET_CAREER_FAILURE,
  });

  export const updatecareer = (data) => ({
    type: UPDATE_CAREER,
    payload: data,
  });
  
  export const updatecareerSuccess = (data) => ({
    type: UPDATE_CAREER_SUCCESS,
    payload: data,
  });
  
  export const updatecareerFailure = () => ({
    type: UPDATE_CAREER_FAILURE,
  });

  export const deletecareer = (data) => ({
    type: DELETE_CAREER,
    payload: data,
  });
  
  export const deletecareerSuccess = (data) => ({
    type: DELETE_CAREER_SUCCESS,
    payload: data,
  });
  
  export const deletecareerFailure = () => ({
    type: DELETE_CAREER_FAILURE,
  });
  