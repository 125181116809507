export const CREATE_USERLIST = "CREATE_USERLIST";
export const CREATE_USERLIST_SUCCESS = "CREATE_USERLIST_SUCCESS";
export const CREATE_USERLIST_FAILURE = "CREATE_USERLISTLOGIN_FAILURE";

export const GET_USERLIST = "GET_USERLIST";
export const GET_USERLIST_SUCCESS = "GET_USERLIST_SUCCESS";
export const GET_USERLIST_FAILURE = "GET_USERLIST_FAILURE";

export const UPDATE_USERLIST = "UPDATE_USERLIST";
export const UPDATE_USERLIST_SUCCESS = "UPDATE_USERLIST_SUCCESS";
export const UPDATE_USERLIST_FAILURE = "UPDATE_USERLIST_FAILURE";


export const DELETE_USERLIST = "DELETE_USERLIST";
export const DELETE_USERLIST_SUCCESS = "DELETE_USERLIST_SUCCESS";
export const DELETE_USERLIST_FAILURE = "DELETE_USERLIST_FAILURE";