import { useEffect, useState } from 'react';
import { TechnologiesList } from '../../data';
export default function Technologies() {
    const [projects, setProjects] = useState([]);

    useEffect(() => {
            setProjects(TechnologiesList);

    }, []);

    return (
        <div className="container-fluid project py-5 mb-5"  style={{boxShadow: '0 4px 2px -2px gray'}}>
            <div className="container">
                <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{maxWidth: "600px"}}>
                    <h5 className="text-brand-primary">Technologies</h5>
                </div>
                <div className="row g-5">
                    {projects.slice(0,6).map((project, index) => (
                        <div key={index} className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay={`.${index + 3}s`}>
                            <div className="project-item">
                                <div className="project-img">
                                    <img src={project.img} className="img-fluid w-100 rounded" alt=""/>
                                    <div className="project-content">
                                        <a href={project.link} className="text-center">
                                            <h4 className="text-brand-green">{project.title}</h4>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div>
                <a href="technologies" className="btn btn-primary bg-brand-primary mt-md-5 mt-4 text-white px-5 py-3 rounded-pill fit-content">See More</a>
            </div>
            </div>
        </div>
    );
}
