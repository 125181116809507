import React, { useState,useCallback } from 'react';
import './comaon.css';
import NavBar from './landingPageComponent/navBar';
import Topbar from './landingPageComponent/topBar';
import { createcareer ,updatecareer } from "../actions/career";
import { useDispatch, useSelector } from "react-redux";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import Footer from './landingPageComponent/footer';
function Careers() {
  const [token, setToken] = useState("");
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    name: '',
    position: '',
    message: '',
    email:'',
    cv: null,
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, cv: file });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateForm(formData);
    if (Object.keys(errors).length === 0) {
      const formDataToSend = new FormData();
      formDataToSend.append('name', formData.name);
      formDataToSend.append('position', formData.position);
      formDataToSend.append('message', formData.message);
      formDataToSend.append('email', formData.email);
      formDataToSend.append('resume', formData.cv);

      // Simulate sending the formData to a placeholder URL
      
      if(token) dispatch( createcareer(formDataToSend));
    } else {
      setErrors(errors);
    }
  };

  const validateForm = (formData) => {
    let errors = {};
    if (!formData.name.trim()) {
      errors.name = 'Name is required';
    }
    if (!formData.email.trim()) {
      errors.name = 'email is required';
    }
    if (!formData.position.trim()) {
      errors.position = 'Position applying for is required';
    }
    if (!formData.message.trim()) {
      errors.message = 'Message is required';
    }
    if (!formData.cv) {
      errors.cv = 'CV/Resume is required';
    }
    return errors;
  };
  const setTokenFunc = useCallback((getToken) => {
    setToken(getToken);
  }, []);
  return (
    <>
      <Topbar />
      <NavBar />
      <div className='container mt-md-5 mt-3 pt-md-5 pt-2'>
        <div className='row'>
          <div className='col-md-4'>
            <img src='/assets/5363881.jpg' className='img-fluid' alt='Placeholder' />
          </div>
          <div className='col-md-8 p-5'>
            <form onSubmit={handleSubmit} className='needs-validation' noValidate>
              <div className='row'>
                <div className='col-md-6 mb-3 d-flex align-items-start flex-column'>
                  <label htmlFor='name' className='mb-2'>Name:</label>
                  <input
                    type='text'
                    className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                    id='name'
                    name='name'
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                  <div className='invalid-feedback'>{errors.name}</div>
                </div>
                <div className='col-md-6 mb-3 d-flex align-items-start flex-column'>
                  <label htmlFor='position' className='mb-2'>Position applying for:</label>
                  <select
                    className={`form-control ${errors.position ? 'is-invalid' : ''}`}
                    id='position'
                    name='position'
                    value={formData.position}
                    onChange={handleChange}
                    required
                  >
                    <option value=''>Select Position</option>
                    <option value="devOps ">DevOps</option>
                <option value="andriod_developers ">
                  Andriod / iOS Developer
                </option>
                <option value=".net-developer ">.Net Developer</option>
                <option value="python-developers ">Python Developer</option>
                <option value="seo-experts ">SEO Expert</option>
                <option value="bi-experts ">BI Expert</option>
                <option value="mercedes ">HR &amp; Admin Executive</option>
                <option value="business-bxecutive ">Business Executive</option>
                <option value="business-analytics ">Business Analytics</option>
                <option value="others ">Others</option>
                  </select>
                  <div className='invalid-feedback'>{errors.position}</div>
                </div>
                <div className='col-md-6 mb-3 d-flex align-items-start flex-column'>
                  <label htmlFor='email' className='mb-2'>Email:</label>
                  <input
                    type='text'
                    className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                    id='email'
                    name='email'
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                  <div className='invalid-feedback'>{errors.email}</div>
                </div>
                <div className='col-md-6 mb-3 d-flex align-items-start flex-column'>
                <label htmlFor='cv' className='mb-2'>Upload CV/Resume:</label>
                <input
                  type='file'
                  className={`form-control-file ${errors.cv ? 'is-invalid' : ''}`}
                  id='cv'
                  name='cv'
                  onChange={handleFileChange}
                  accept='.pdf,.doc,.docx'
                  required
                />
                <div className='invalid-feedback'>{errors.cv}</div>
              </div>
              </div>
              <div className='mb-3 d-flex align-items-start flex-column'>
                <label htmlFor='message' className='mb-2'>Message:</label>
                <textarea
                  className={`form-control ${errors.message ? 'is-invalid' : ''}`}
                  id='message'
                  name='message'
                  value={formData.message}
                  onChange={handleChange}
                  required
                  style={{ height: "200px" }}
                ></textarea>
                <div className='invalid-feedback'>{errors.message}</div>
              </div>
              <button type='submit' className='btn btn-secondary text-white py-2 px-4 mt-5 d-flex align-items-start flex-column'>Submit</button>
              <GoogleReCaptcha
                          className="google-recaptcha-custom-class"
                          onVerify={setTokenFunc}
                          refreshReCaptcha={refreshReCaptcha}
                        />
            </form>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
}

export default Careers;
