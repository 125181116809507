export const GET_ATTENDANCE = "COURSE_CATEGORY";
export const GET_ATTENDANCE_SUCCESS = "COURSE_CATEGORY_SUCCESS";
export const GET_ATTENDANCE_FAILURE = "COURSE_CATEGORY_FAILURE";
export const REMOVE_ATTENDANCE_AT_INDEX = "REMOVE_ATTENDANCE_AT_INDEX";

export const COURSE = "COURSE";
export const COURSE_SUCCESS = "COURSE_SUCCESS";
export const COURSE_FAILURE = "COURSE_FAILURE";

export const TOPICS = "TOPICS";
export const TOPICS_SUCCESS = "TOPICS_SUCCESS";
export const TOPICS_FAILURE = "TOPICS_FAILURE";

export const VIDEOS = "VIDEOS";
export const VIDEOS_SUCCESS = "VIDEOS_SUCCESS";
export const VIDEOS_FAILURE = "VIDEOS_FAILURE";

export const SPEAKERS = "SPEAKERS";
export const SPEAKERS_SUCCESS = "SPEAKERS_SUCCESS";
export const SPEAKERS_FAILURE = "SPEAKERS_FAILURE";

export const USERLIST = "USERLIST";
export const USERLIST_SUCCESS = "USERLIST_SUCCESS";
export const USERLIST_FAILURE = "USERLIST_FAILURE";

export const GETATTENDANCEBYHOURS = "GETATTENDANCEBYHOURS";
export const GETATTENDANCEBYHOURS_SUCCESS = "GETATTENDANCEBYHOURS_SUCCESS";
export const GETATTENDANCEBYHOURS_FAILURE = "GETATTENDANCEBYHOURS_FAILURE";


export const ATTENDANCEUPDATE = "ATTENDANCEUPDATE";
export const ATTENDANCEUPDATE_SUCCESS = "ATTENDANCEUPDATE_SUCCESS";
export const ATTENDANCEUPDATE_FAILURE = "ATTENDANCEUPDATE_FAILURE";