import {
    CREATE_DESIGNATIONS,
    CREATE_DESIGNATIONS_SUCCESS,
    CREATE_DESIGNATIONS_FAILURE,
    GET_DESIGNATIONS,
    GET_DESIGNATIONS_SUCCESS,
    GET_DESIGNATIONS_FAILURE,
    UPDATE_DESIGNATIONS,
    UPDATE_DESIGNATIONS_SUCCESS,
    UPDATE_DESIGNATIONS_FAILURE,
    DELETE_DESIGNATIONS,
    DELETE_DESIGNATIONS_SUCCESS,
    DELETE_DESIGNATIONS_FAILURE
  } from "./actionTypes";
  
  // Signin actions function
  export const createdesignations = (data) => ({
    type: CREATE_DESIGNATIONS,
    payload: data,
  });
  
  export const createdesignationsSuccess = (data) => ({
    type: CREATE_DESIGNATIONS_SUCCESS,
    payload: data,
  });
  
  export const createdesignationsFailure = () => ({
    type: CREATE_DESIGNATIONS_FAILURE,
  });

  export const getdesignations = (data) => ({
    type: GET_DESIGNATIONS,
    payload: data,
  });
  
  export const getdesignationsSuccess = (data) => ({
    type: GET_DESIGNATIONS_SUCCESS,
    payload: data,
  });
  
  export const getdesignationsFailure = () => ({
    type: GET_DESIGNATIONS_FAILURE,
  });

  export const updatedesignations = (data) => ({
    type: UPDATE_DESIGNATIONS,
    payload: data,
  });
  
  export const updatedesignationsSuccess = (data) => ({
    type: UPDATE_DESIGNATIONS_SUCCESS,
    payload: data,
  });
  
  export const updatedesignationsFailure = () => ({
    type: UPDATE_DESIGNATIONS_FAILURE,
  });

  export const deletedesignations = (data) => ({
    type: DELETE_DESIGNATIONS,
    payload: data,
  });
  
  export const deletedesignationsSuccess = (data) => ({
    type: DELETE_DESIGNATIONS_SUCCESS,
    payload: data,
  });
  
  export const deletedesignationsFailure = () => ({
    type: DELETE_DESIGNATIONS_FAILURE,
  });
  