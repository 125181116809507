import {
    CREATE_CONTACTUS,
    CREATE_CONTACTUS_SUCCESS,
    CREATE_CONTACTUS_FAILURE,
    GET_CONTACTUS,
    GET_CONTACTUS_SUCCESS,
    GET_CONTACTUS_FAILURE,
    UPDATE_CONTACTUS,
    UPDATE_CONTACTUS_SUCCESS,
    UPDATE_CONTACTUS_FAILURE,
    DELETE_CONTACTUS,
    DELETE_CONTACTUS_SUCCESS,
    DELETE_CONTACTUS_FAILURE
  } from "./actionTypes";
  
  // Signin actions function
  export const createcontactUs = (data) => ({
    type: CREATE_CONTACTUS,
    payload: data,
  });
  
  export const createcontactUsSuccess = (data) => ({
    type: CREATE_CONTACTUS_SUCCESS,
    payload: data,
  });
  
  export const createcontactUsFailure = () => ({
    type: CREATE_CONTACTUS_FAILURE,
  });

  export const getcontactUs = (data) => ({
    type: GET_CONTACTUS,
    payload: data,
  });
  
  export const getcontactUsSuccess = (data) => ({
    type: GET_CONTACTUS_SUCCESS,
    payload: data,
  });
  
  export const getcontactUsFailure = () => ({
    type: GET_CONTACTUS_FAILURE,
  });

  export const updatecontactUs = (data) => ({
    type: UPDATE_CONTACTUS,
    payload: data,
  });
  
  export const updatecontactUsSuccess = (data) => ({
    type: UPDATE_CONTACTUS_SUCCESS,
    payload: data,
  });
  
  export const updatecontactUsFailure = () => ({
    type: UPDATE_CONTACTUS_FAILURE,
  });

  export const deletecontactUs = (data) => ({
    type: DELETE_CONTACTUS,
    payload: data,
  });
  
  export const deletecontactUsSuccess = (data) => ({
    type: DELETE_CONTACTUS_SUCCESS,
    payload: data,
  });
  
  export const deletecontactUsFailure = () => ({
    type: DELETE_CONTACTUS_FAILURE,
  });
  