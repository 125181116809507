import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  CREATE_CONTACTUS,
  GET_CONTACTUS,
  UPDATE_CONTACTUS,
  DELETE_CONTACTUS
} from "../actions/contactUs/actionTypes";
import {
  createcontactUsSuccess,
  createcontactUsFailure,
  getcontactUsSuccess,
  getcontactUsFailure,
  updatecontactUsSuccess,
  updatecontactUsFailure,
  deletecontactUsSuccess,
  deletecontactUsFailure
} from "../actions/contactUs/index";
import { pushNotification, } from "../utils/notifications";
import {
  postRequestWithTenat,
  getRequestWithTenant,
  patchRequestWithTokenTenant,
  deleteRequestWithTokenTenant
} from "./request";
import URls from "../constants/urls";

// //course category generator function
function* createcontactUsCall(action) {
  try {
    const response = yield call(postRequestWithTenat, URls.contactUs, action.payload);

    if (response?.status === 200) {
      pushNotification(
        `${response?.data.message}`,
        "success",
        "TOP_CENTER",
        1000
      );
      yield put(createcontactUsSuccess(response.data));
      action.payload.navigate("/contactUs")
    } else {
      pushNotification(`${response?.data?.message}`, "");
    }
  } catch (error) {
    yield put(createcontactUsFailure());
  }
}

function* updatecontactUsCall(action) {
  try {
    const response = yield call(patchRequestWithTokenTenant, `${URls.contactUs}/${action.payload.id}`,action.payload.data);
    if (response?.status === 200) {
      pushNotification(
        `${response?.data.message}`,
        "success",
        "TOP_CENTER",
        1000
      );
      yield put(updatecontactUsSuccess(response.data));
    } else {
      pushNotification(`${response?.data?.message}`, "");
    }
  } catch (error) {
    yield put(updatecontactUsFailure());
  }
}

function* deletecontactUsCall(action) {
  try {
    const response = yield call(deleteRequestWithTokenTenant, `${URls.contactUs}/${action.payload.id}`);
  
    if (response?.status === 200) {
      pushNotification(
        `${response?.data.message}`,
        "success",
        "TOP_CENTER",
        1000
      );
      yield put(deletecontactUsSuccess(response.data));
    } else {
      pushNotification(`${response?.data?.message}`, "");
    }
  } catch (error) {
    yield put(deletecontactUsFailure());
  }
}

function* getcontactUsCall(action) {
  try {
    console.log(action.payload)
    const response = yield call(getRequestWithTenant, `${URls.contactUs}?limit=${action.payload.filter.pageSize}&page=${action.payload.filter.pageNumber}`);

    if (response?.status === 200) {
      yield put(getcontactUsSuccess(response.data));
    }
  } catch (error) {
    yield put(getcontactUsFailure());
  }
}


function* watchGetRequest() {
  yield takeLatest(CREATE_CONTACTUS, createcontactUsCall);
  yield takeLatest(GET_CONTACTUS, getcontactUsCall);
  yield takeLatest(UPDATE_CONTACTUS, updatecontactUsCall);
  yield takeLatest(DELETE_CONTACTUS, deletecontactUsCall);
}
export default function* sagas() {
  yield all([watchGetRequest()]);
}
