export const EMAILVERIFICATION = "EMAILVERIFICATION";
export const EMAILVERIFICATION_SUCCESS = "EMAILVERIFICATION_SUCCESS";
export const EMAILVERIFICATION_FAILURE = "EMAILVERIFICATION_FAILURE";

export const RESETPASSWORD = "RESETPASSWORD";
export const RESETPASSWORD_SUCCESS = "RESETPASSWORD_SUCCESS";
export const RESETPASSWORD_FAILURE = "RESETPASSWORD_FAILURE";

export const EMAILSTATUS = "EMAILSTATUS";
export const  EMAILSTATUS_SUCCESS = "EMAILSTATUS_SUCCESS";
export const EMAILSTATUS_FAILURE = "EMAILSTATUS_FAILURE";

