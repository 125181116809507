import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import './styles.css';

export default function LoadingScreen (){
    const navigate = useNavigate(); // Initialize the navigate function

    useEffect(() => {
        const timeout = setTimeout(() => {
            navigate('/home'); 
        }, 3000);

        return () => clearTimeout(timeout); 
    }, [navigate]);

    return (
        <>
            <div className="intro-img">
                <img src="/assets/Background.png" alt="" className="img-fluid move_logo"/>
            </div>
            <h2 className="text-brand">Business | Technology | Empowerment</h2>
            <p className="contact-brand"><a href="mailto:queries@saldri.com">queries@saldri.com</a></p>
            <div className="loader_box">
                <span className="blink">Loading...</span>
                <div className="cl1"></div>
                <div className="cl1 cl2"></div>
                <div className="cl1 cl3"></div>
                <div className="cl1 cl4"></div>
            </div>
        </>
    );
}
