import {
  CREATE_DEPARTMENT,
  CREATE_DEPARTMENT_SUCCESS,
  CREATE_DEPARTMENT_FAILURE,
  GET_DEPARTMENT,
  GET_DEPARTMENT_SUCCESS,
  GET_DEPARTMENT_FAILURE,
  UPDATE_DEPARTMENT,
  UPDATE_DEPARTMENT_SUCCESS,
  UPDATE_DEPARTMENT_FAILURE,
  DELETE_DEPARTMENT,
  DELETE_DEPARTMENT_SUCCESS,
  DELETE_DEPARTMENT_FAILURE
} from "../actions/department/actionTypes";

const initialState = {
  getListLoading: false,
  data: null,
  createData:null
};

// course category reducer funtions
const createdepartment = (state, action) => ({
  ...state,
  getListLoading: true,
});

const createdepartmentSuccess = (state, action) => {

  return {
    ...state,
    getListLoading: false,
    createData: action.payload,
  };
};

const createdepartmentFailed = (state, action) => ({
  ...state,
  getListLoading: false,
  createData: [],
});

const getdepartment = (state, action) => ({
  ...state,
  // getListLoading: true,
});

const getdepartmentSuccess = (state, action) => {
 
  return {
    ...state,
    // getListLoading: false,
    data: action.payload,
  };
};

const getdepartmentFailed = (state, action) => ({
  ...state,
  // getListLoading: false,
  data: [],
});

const updatedepartment = (state, action) => ({
  ...state,
   getListLoading: true,
});

const updatedepartmentSuccess = (state, action) => {

  return {
    ...state,
    getListLoading: false,
    data: action.payload,
  };
};  
const updatedepartmentFailed = (state, action) => ({
  ...state,
  getListLoading: false,
  data: [],
});

const deletedepartment = (state, action) => ({
  ...state,
   getListLoading: true,
});

const deletedepartmentSuccess = (state, action) => {
  
  return {
    ...state,
    getListLoading: false,
  };
};  
const deletedepartmentFailed = (state, action) => ({
  ...state,
  getListLoading: false,
  data: [],
});

const departmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_DEPARTMENT:
      return createdepartment(state, action);
    case CREATE_DEPARTMENT_SUCCESS:
      return createdepartmentSuccess(state, action);
    case CREATE_DEPARTMENT_FAILURE:
      return createdepartmentFailed(state, action);
    case GET_DEPARTMENT:
      return getdepartment(state, action);
    case GET_DEPARTMENT_SUCCESS:
      return getdepartmentSuccess(state, action);
    case GET_DEPARTMENT_FAILURE:
      return getdepartmentFailed(state, action);
    case UPDATE_DEPARTMENT:
      return updatedepartment(state, action);
    case UPDATE_DEPARTMENT_SUCCESS:
      return updatedepartmentSuccess(state, action);
    case UPDATE_DEPARTMENT_FAILURE:
      return updatedepartmentFailed(state, action);
    case DELETE_DEPARTMENT:
      return deletedepartment(state, action);
    case DELETE_DEPARTMENT_SUCCESS:
      return deletedepartmentSuccess(state, action);
    case DELETE_DEPARTMENT_FAILURE:
      return deletedepartmentFailed(state, action);
    default:
      return state;
  }
};

export default departmentReducer;
