import React, { useState } from 'react';
import '../components/comaon.css';
import Topbar from '../components/landingPageComponent/topBar';
import NavBar from '../components/landingPageComponent/navBar';
import { ServicesAll } from '../data';
import Footer from '../components/landingPageComponent/footer';

function Service() {
  const [isImageLeft, setIsImageLeft] = useState(true);

  // Function to toggle the position of image and text
  const toggleImagePosition = () => {
    setIsImageLeft(prev => !prev);
  };

  // Array of colors for headings
  const colors = ["#E84336", "#103F72", "#34A754", "#FBBC16", "#517BBD"];

  return (
    <>
      <Topbar />
      <NavBar />
      <div className="services-section">
        {ServicesAll.map((service, index) => (
          <div key={service.id} className={`service container ${index % 2 === 0 ? 'image-left' : 'image-right'}`}>
            <div className='row container align-items-center'>
              {/* Conditionally render image and text based on the index */}
              {index % 2 === 0 ? (
                <>
                  <div className="service-icon col-md-6 p-md-5">
                    <img src={service.src} alt={service.title} className="service-image img-fluid floating" />
                  </div>
                  <div className="service-details col-md-6 p-md-5 text-left">
                    <h2 style={{ color: colors[index % colors.length] }}>{service.title}</h2>
                    <p>{service.description}</p>
                  </div>
                </>
              ) : (
                <>
                  <div className="service-details col-md-6 p-md-5 text-left">
                    <h2 style={{ color: colors[index % colors.length] }}>{service.title}</h2>
                    <p>{service.description}</p>
                  </div>
                  <div className="service-icon col-md-6 p-md-5">
                    <img src={service.src} alt={service.title} className="service-image img-fluid floating" />
                  </div>
                </>
              )}
            </div>
          </div>
        ))}
      </div>
      <Footer/>
    </>
  );
}

export default Service;
