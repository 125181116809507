export const CREATE_EMAIL_TEMPLATE = "CREATE_EMAIL_TEMPLATE";
export const CREATE_EMAIL_TEMPLATE_SUCCESS = "CREATE_EMAIL_TEMPLATE_SUCCESS";
export const CREATE_EMAIL_TEMPLATE_FAILURE = "CREATE_EMAIL_TEMPLATELOGIN_FAILURE";

export const GET_EMAIL_TEMPLATE = "GET_EMAIL_TEMPLATE";
export const GET_EMAIL_TEMPLATE_SUCCESS = "GET_EMAIL_TEMPLATE_SUCCESS";
export const GET_EMAIL_TEMPLATE_FAILURE = "GET_EMAIL_TEMPLATE_FAILURE";

export const UPDATE_EMAIL_TEMPLATE = "UPDATE_EMAIL_TEMPLATE";
export const UPDATE_EMAIL_TEMPLATE_SUCCESS = "UPDATE_EMAIL_TEMPLATE_SUCCESS";
export const UPDATE_EMAIL_TEMPLATE_FAILURE = "UPDATE_EMAIL_TEMPLATE_FAILURE";


export const DELETE_EMAIL_TEMPLATE = "DELETE_EMAIL_TEMPLATE";
export const DELETE_EMAIL_TEMPLATE_SUCCESS = "DELETE_EMAIL_TEMPLATE_SUCCESS";
export const DELETE_EMAIL_TEMPLATE_FAILURE = "DELETE_EMAIL_TEMPLATE_FAILURE";