const URLs = {
  // Auth API's
  loginurl: "/auth/login",
  contactUs:'/contactUs',
  signupUrl: "/auth/register",
  emailVerificationUrl: "/auth/forgot-password",
  resetPassword: "/auth/reset-password",
  logOut: "/auth/logout",
  emailStatus: "api/v1/users/email/verify/",
  attendanceAdjustment:"/attendance",
  career:'/career',
  getAttendanceByHours:"/attendance/by-hours",
  getAllUsers:'users/by/department-and-designation',
  getDepartment:'/department',
  getDesignation:'/designation',
  table_url:'/table',
  self_table_url:'/table/self-generated',
  carList:'/carList',
  UserList:'/add_api_url',
  getUserDepartmentDesignation:'/users/by/department-and-designation',
  sandUsersEmails:'/users/send-invite-emails',
  department: "/department",
  designations:'/designations',
  status: "/status",
  modules: "/modules",
  userLogInStatus: "/auth/verify-login-status",
  emailTemplate: "email-templates",
  tokenRefrash:'/auth/refresh-tokens',
  getQueues:"/auth/get-queues",
  user:'/users',
  course: "courses/all/",
  topics: "courses/topics/",
  courseCategory: "courses/categories/",
  videos: "attendance/2",
  Speakers: "api/v1/users/getSpeakers/",
  //mainUrl: "http://192.168.18.11:8000/",
  baseURL: "http://192.168.18.36:8000/",
  mainUrl: "http://ec2-18-191-81-48.us-east-2.compute.amazonaws.com:8000/",
  baseUrl: "http://ec2-18-191-81-48.us-east-2.compute.amazonaws.com:8000/",
  imageBaseUrl: "https://lms-backend-static.s3.amazonaws.com/"
};

export default URLs;
