import React from 'react';
import { Container } from 'react-bootstrap';
import { HashLink } from 'react-router-hash-link';
import IconButton from '@mui/material/IconButton';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';

const Topbar = () => {
  const iconStyle = { color: '#34A753',padding: 0  };
  return (
    <div className="container-fluid main-topbar-container py-2 d-none d-md-flex">
      <Container>
        <div className="d-flex justify-content-between topbar">
          <div className="top-info">
            <small className="me-3 text-white-50">
              <HashLink to="#">
                <IconButton color="inherit">
                  <LocationOnIcon style={iconStyle} />
                </IconButton>
              </HashLink>
              Daftarkhuwan DHA phase 5 , Lahore
            </small>
            <small className="me-3 text-white-50">
              <HashLink to="#">
                <IconButton color="inherit">
                  <EmailIcon style={iconStyle} />
                </IconButton>
              </HashLink>
              <a href="mailto:queries@saldri.com" style={{color:'rgba(255,255,255,.5)',textDecoration:'none'}}>queries@saldri.com</a>
            </small>
          </div>
          <div id="note" className="text-note d-none d-xl-flex ">
            <small>Note: We help you to Grow your Business</small>
          </div>
          <div className="top-link">
                        <a href="" className="bg-light nav-fill btn rounded-circle"><i className="fab fa-facebook-f text-primary"></i></a>
                        <a href="" className="bg-light nav-fill btn rounded-circle"><i className="fab fa-twitter text-primary"></i></a>
                        <a href="" className="bg-light nav-fill btn rounded-circle"><i className="fab fa-instagram text-primary"></i></a>
                        <a href="" className="bg-light nav-fill btn rounded-circle me-0"><i className="fab fa-linkedin-in text-primary"></i></a>
                    </div>
        </div>
      </Container>
    </div>
  );
};

export default Topbar;
