import {
    CREATE_DEPARTMENT,
    CREATE_DEPARTMENT_SUCCESS,
    CREATE_DEPARTMENT_FAILURE,
    GET_DEPARTMENT,
    GET_DEPARTMENT_SUCCESS,
    GET_DEPARTMENT_FAILURE,
    UPDATE_DEPARTMENT,
    UPDATE_DEPARTMENT_SUCCESS,
    UPDATE_DEPARTMENT_FAILURE,
    DELETE_DEPARTMENT,
    DELETE_DEPARTMENT_SUCCESS,
    DELETE_DEPARTMENT_FAILURE
  } from "./actionTypes";
  
  // Signin actions function
  export const createdepartment = (data) => ({
    type: CREATE_DEPARTMENT,
    payload: data,
  });
  
  export const createdepartmentSuccess = (data) => ({
    type: CREATE_DEPARTMENT_SUCCESS,
    payload: data,
  });
  
  export const createdepartmentFailure = () => ({
    type: CREATE_DEPARTMENT_FAILURE,
  });

  export const getdepartment = (data) => ({
    type: GET_DEPARTMENT,
    payload: data,
  });
  
  export const getdepartmentSuccess = (data) => ({
    type: GET_DEPARTMENT_SUCCESS,
    payload: data,
  });
  
  export const getdepartmentFailure = () => ({
    type: GET_DEPARTMENT_FAILURE,
  });

  export const updatedepartment = (data) => ({
    type: UPDATE_DEPARTMENT,
    payload: data,
  });
  
  export const updatedepartmentSuccess = (data) => ({
    type: UPDATE_DEPARTMENT_SUCCESS,
    payload: data,
  });
  
  export const updatedepartmentFailure = () => ({
    type: UPDATE_DEPARTMENT_FAILURE,
  });

  export const deletedepartment = (data) => ({
    type: DELETE_DEPARTMENT,
    payload: data,
  });
  
  export const deletedepartmentSuccess = (data) => ({
    type: DELETE_DEPARTMENT_SUCCESS,
    payload: data,
  });
  
  export const deletedepartmentFailure = () => ({
    type: DELETE_DEPARTMENT_FAILURE,
  });
  