import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  CREATE_DEPARTMENT,
  GET_DEPARTMENT,
  UPDATE_DEPARTMENT,
  DELETE_DEPARTMENT
} from "../actions/department/actionTypes";
import {
  createdepartmentSuccess,
  createdepartmentFailure,
  getdepartmentSuccess,
  getdepartmentFailure,
  updatedepartmentSuccess,
  updatedepartmentFailure,
  deletedepartmentSuccess,
  deletedepartmentFailure
} from "../actions/department/index";
import { pushNotification, } from "../utils/notifications";
import {
  postRequestWithTenat,
  getRequestWithTenant,
  patchRequestWithTokenTenant,
  deleteRequestWithTokenTenant
} from "./request";
import URls from "../constants/urls";

// //course category generator function
function* createdepartmentCall(action) {
  try {
    const response = yield call(postRequestWithTenat, URls.department, action.payload);

    if (response?.status === 200) {
      pushNotification(
        `${response?.data.message}`,
        "success",
        "TOP_CENTER",
        1000
      );
      yield put(createdepartmentSuccess(response.data));
      action.payload.navigate("/department")
    } else {
      pushNotification(`${response?.data?.message}`, "");
    }
  } catch (error) {
    yield put(createdepartmentFailure());
  }
}

function* updatedepartmentCall(action) {
  try {
    const response = yield call(patchRequestWithTokenTenant, `${URls.department}/${action.payload.id}`,action.payload.data);
    if (response?.status === 200) {
      pushNotification(
        `${response?.data.message}`,
        "success",
        "TOP_CENTER",
        1000
      );
      yield put(updatedepartmentSuccess(response.data));
    } else {
      pushNotification(`${response?.data?.message}`, "");
    }
  } catch (error) {
    yield put(updatedepartmentFailure());
  }
}

function* deletedepartmentCall(action) {
  try {
    const response = yield call(deleteRequestWithTokenTenant, `${URls.department}/${action.payload.id}`);
  
    if (response?.status === 200) {
      pushNotification(
        `${response?.data.message}`,
        "success",
        "TOP_CENTER",
        1000
      );
      yield put(deletedepartmentSuccess(response.data));
    } else {
      pushNotification(`${response?.data?.message}`, "");
    }
  } catch (error) {
    yield put(deletedepartmentFailure());
  }
}

function* getdepartmentCall(action) {
  try {
    const response = yield call(getRequestWithTenant, `${URls.department}?limit=${action.payload.filter.pageSize}&page=${action.payload.filter.pageNumber}`);

    if (response?.status === 200) {
      yield put(getdepartmentSuccess(response.data));
    }
  } catch (error) {
    yield put(getdepartmentFailure());
  }
}


function* watchGetRequest() {
  yield takeLatest(CREATE_DEPARTMENT, createdepartmentCall);
  yield takeLatest(GET_DEPARTMENT, getdepartmentCall);
  yield takeLatest(UPDATE_DEPARTMENT, updatedepartmentCall);
  yield takeLatest(DELETE_DEPARTMENT, deletedepartmentCall);
}
export default function* sagas() {
  yield all([watchGetRequest()]);
}
