import React, { useEffect, useState } from 'react';

const reasons = [
  { text: 'AFFORDABLE' },
  { text: 'AGILE' },
  { text: 'RESPONSIVE' },
  { text: 'EMPATHETIC' }
];

const counters = [
  { number: 0, label: 'Happy Clients', stop: 30 },
  { number: 0, label: 'Project Complete', stop: 55 }
];

export default function ChooseUS() {
  const [animatedCounters, setAnimatedCounters] = useState([...counters]);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    function handleScroll() {
      const element = document.getElementById('chooseUs');
      if (!element) return;

      const rect = element.getBoundingClientRect();
      const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
      const isElementVisible = !(rect.bottom < 0 || rect.top - viewHeight >= 0);
      setIsVisible(isElementVisible);
    }

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Check visibility on initial render

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      const interval = setInterval(() => {
        const updatedCounters = animatedCounters.map(counter => {
          const newNumber = counter.number === counter.stop ? counter.number : counter.number + 1;
          return { ...counter, number: newNumber };
        });
        setAnimatedCounters(updatedCounters);
      }, 50); // Adjust the interval time as needed

      return () => clearInterval(interval);
    }
  }, [isVisible, animatedCounters]);

  return (
    <>
      <div id="chooseUs" className="container-fluid feature pt-5" style={{backgroundColor: 'rgb(24, 66, 182)'}}>
        <div className="container pt-5">
          <div className="row g-5">
            <div className="col-lg-6 align-self-center mb-md-5 pb-md-5 wow fadeIn" data-wow-delay="0.3s">
              <div className="btn btn-sm border text-left rounded-pill text-white px-3 mb-3">Why Choose Us</div>
              <h1 className="text-white text-left mb-4">We provide innovative ideas and solutions</h1>
              <p className="text-light text-left mb-4">We are highly skilled with the latest technologies to empower your business and needs. We have vast experience in developing and marketing products in multiple domains like Telecommunications, Finance, and E-commerce. We also deal in cloud infrastructure and provide DevOps services and fulfillment Amazon services to the clients.</p>
              {reasons.map((reason, index) => (
                <div key={index} className="d-flex align-items-center text-white mb-3">
                  <div className="btn-sm-square bg-white text-primary rounded-circle me-3">
                    <i className="fa fa-check"></i>
                  </div>
                  <span>{reason.text}</span>
                </div>
              ))}
              <div className="row g-4 pt-3">
                {animatedCounters.map((counter, index) => (
                  <div key={index} className="col-sm-6">
                    <div className="d-flex rounded p-3" style={{ background: "rgba(256, 256, 256, 0.1)" }}>
                      <i className={index === 0 ? "fa fa-users fa-3x text-white" : "fa fa-check fa-3x text-white"}></i>
                      <div className="ms-3">
                        <h2 className="text-white mb-0" data-toggle="counter-up">{counter.number}+</h2>
                        <p className="text-white mb-0">{counter.label}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-lg-6 align-self-end text-center text-md-end wow fadeIn" data-wow-delay="0.5s">
              <img className="img-fluid" src="assets/feature.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
